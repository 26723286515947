import React, {useContext, useReducer} from 'react'
import {ThemeProvider, createGlobalStyle} from 'styled-components'
import theme from './theme'
import './index.css'
import css from '@styled-system/css'
import styled, {keyframes} from 'styled-components'
import {Link} from 'gatsby'
import {useStore} from '../state'
import {FollowUs} from '../components/Social'
import Header from '../components/Header'



const fadein = keyframes`

from {
    opacity: 0
}

to {
    opacity: 1
}

`

const Global = createGlobalStyle`

    html, body, #root {
        margin: 0px; padding: 0px;
    }
    body {
        background: ${({theme: {colors: {bg}}}) => bg};
        color: ${({theme: {colors: {text}}}) => text};
        transition: background .4s ease;       
        
        
        
        
    }

    a {
        color: ${({theme: {colors: {text}}}) => text};
    }
    



    .body {
        overflow-x: hidden;
        .main {
            position: relative;

            &.home {
                .follow_us {
                    top: 5px;
                }
            }

            .follow_us {
                padding: 10px;
                height: 210px;
                position: absolute;
                top: 95px;
                right: -145px;

                border-left: 1px solid #ccc;

                flex-direction: column;



                .label {
                    transform: rotate(-90deg);
                    height: 56px;
                    width: 90px;
                    margin-top: 15px;

                }


                .icons {
                    width: 30px;
                    margin-top: 51px;
                    margin-left: 19px;
                    .el  {
                        
                        margin: 10px 0px;
                    }
                }
            }
        }


        &.fadein {
            animation: ${fadein} 1.0s ease;
        }
        

    }



${css({

    'body': {
        fontSize: ['14px', '16px']
    },
    
    '.main': {
        margin: '0px auto',
        maxWidth: ['100%', 1040],
        paddingLeft:[10, 0],
        paddingRight:[10, 0],
    },

    '.body': {
        
        marginTop: [10, 40],
        
        

    },
    
})}   
`


const FooterWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding:40px 10px;
    margin-bottom: 10px;


    border-top: 1px solid #ccc;

    a {
        text-decoration: underline;
    }
    
    .contacts { 
        display: flex;
        flex-direction: column;

        .label {
            display: none;

        }

        .icons {
            display: flex;
            margin: 20px 0px;
            .el {
                margin-right: 20px;
            }
        }
    }
    ${css({
        flexDirection: ['column', 'row']
    })}
`

const Footer = ({pathname}) => {

    const {image_show} = useStore()

    return image_show ? null : <FooterWrapper className="main">
        <div className="contacts">
            <div>     info@naice.one   |  +49 (0) 30-12053561</div>
            <div><FollowUs /></div>
        </div>

        <div><Link className={pathname.indexOf("/imprint/") === 0 ? 'active': ''}  to="/imprint">Imprint</Link></div>
    </FooterWrapper>
}


const ColorModeContext = React.createContext()

export const useColorModeContext = () => useContext(ColorModeContext)

const getTheme = (mode) => mode === 'default' ? theme : {...theme, colors: theme.colors.modes[mode]}


const imagesPathRegex = new RegExp(/\/projects\/([^\/]+)\/images/)
const projectsPathRegex = new RegExp(/\/projects/)


export default ({children, location, pageContext}) => {


    const [{mode}, dispatch] = useReducer((state, mode) => ({...state, mode}), {mode: 'default'})
    const {pathname} = location

    if (pageContext.layout === "mobile") {
        return <ColorModeContext.Provider value={[{mode}, dispatch]}>
            <ThemeProvider theme={getTheme(mode)}>
                <Global/>
                {imagesPathRegex.test(location.pathname) ? null : <Header {...{pathname}}/>}
                <div className="body fadein">
                    <div className={`main ${projectsPathRegex.test(pathname) ? "projects" : ""} ${pathname === "/" ? "home" : ""}`}>
                        {children}
                        
                    </div>
                </div>
                {imagesPathRegex.test(location.pathname) ? null :  <Footer {...{pathname}}/>}
            </ThemeProvider>
        </ColorModeContext.Provider>
      }

    return <ColorModeContext.Provider value={[{mode}, dispatch]}>
        <ThemeProvider theme={getTheme(mode)}>
            <Global/>
            {imagesPathRegex.test(location.pathname) ? null : <Header {...{pathname}}/>}
            <div className="body fadein">
                <div className={`main ${projectsPathRegex.test(pathname) ? "projects" : ""} ${pathname === "/" ? "home" : ""}`}>
                    {children}
                    <FollowUs/>
                </div>
            </div>
            {imagesPathRegex.test(location.pathname) ? null :  <Footer {...{pathname}}/>}
        </ThemeProvider>
    </ColorModeContext.Provider>
}